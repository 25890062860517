<template>
  <div class="login-container">
        <el-form class="login-form" autocomplete="on" label-position="left">

          <div class="title-container">
            <h3 class="title">IP计算器</h3>
          </div>
          <el-tooltip content="例如:192.168.0.1" placement="right">
            <el-form-item prop="IPAddr">
                <el-input
                v-model="IPForm.IPAddr"
                placeholder="IP地址"
                name="username"
                type="text"
                tabindex="1"
                autocomplete="on"
                />
            </el-form-item>
          </el-tooltip>
          <el-tooltip content="例如:255.255.255.0或24" placement="right">
            <el-form-item prop="Mask">
              <el-input
                v-model="IPForm.Mask"
                type="text"
                @keyup.enter.native="handleLogin"
                placeholder="子网掩码"
                tabindex="2"
                autocomplete="on"
              />
            </el-form-item>
          </el-tooltip>

          <el-button type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="greet">计算</el-button>
        </el-form>
        <el-dialog
        class="IPdialog"
        title="结果"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
        <pre>{{dialogMsg}}</pre>
        <span slot="footer" class="dialog-footer">
            <table class="IPtable" border="0" cellpadding="7" frame="below">
              <tr v-for='item in items' :key='item.id'>
                <td align="left">{{ item.titel }}</td>
                <td>{{ item.message }}</td>
              </tr>
            </table>
            <br>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
        </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
        IPForm: {
          IPAddr: '',
          Mask: ''
        },
        capsTooltip: true,
        dialogVisible: false,
        items: [],
        dialogMsg:'',
    }
  },
  methods:{
    handleLogin(){
      //console.log("Login...")
      this.greet()
    },
    handleClose(done) {
        /*this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {})*/
          done()
      },
    greet: function (event) {
      this.dialogMsg = '如有异常请联系admin@mmemm.com提交BUG或建议\n'
      this.items = []
      //console.log($('#app'))
      //console.log('#app')
      let strIpAdd = this.IPForm.IPAddr
      //console.log(strIpAdd)
      //console.log(typeof (strIpAdd))
      let strMask = this.IPForm.Mask
      var isMask = false
      var isIP = false
      var pattern = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])(\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])){3}$/
      if (pattern.test(strIpAdd)) {
        //console.log('IP地址合法')
        this.dialogMsg+='IP地址:'+strIpAdd+'\n'
        isIP = true
      }else{
        //console.log('不是合法的IPv4地址')
        this.dialogMsg+='IPv4地址不合法:'+strIpAdd+'\n'
      }
      let strDianFenIP = strIpAdd.split('.')
      var DianFenMask = []
      let patternMASK = /^(254|252|248|240|224|192|128|0)\.0\.0\.0$|^(255\.(254|252|248|240|224|192|128|0)\.0\.0)$|^(255\.255\.(254|252|248|240|224|192|128|0)\.0)$|^(255\.255\.255\.(254|252|248|240|224|192|128|0))$/
      if (patternMASK.test(strMask)) {
        //console.log('点分子网掩码')
        this.dialogMsg+='子网掩码:'+strMask+'\n'
        isMask = true
        DianFenMask = strMask.split('.')
      }

      let patternMASKn = /^([1-2][0-9]|[0-9]|3[0-2])$/
      if (patternMASKn.test(strMask)) {
        //console.log('位数子网掩码')
        this.dialogMsg+='子网掩码:'+strMask+'\n'
        isMask = true
        let cbMask = parseInt(strMask)
        //console.log(cbMask)
        let cb = parseInt(cbMask / 8)
        for (let i = 0; i < cb; i++) {
          DianFenMask[i] = 255
        }
        DianFenMask[cb] = (255 >> (8 - (cbMask % 8))) << (8 - (cbMask % 8))
        //console.log(DianFenMask[cb])
        for (let i = cb + 1; i < 4; i++) {
          DianFenMask[i] = 0
        }
      }

      //console.log(strDianFenIP)
      //console.log(DianFenMask)
      /* let nIPAdd = []
      strDianFenIP.forEach(element => {
        nIPAdd.push(parseInt(element))
      })
      console.log(nIPAdd[0].toString(2)) */
      let nSubNet = []
      if (isMask && isIP) {
        for (let i = 0; i < 4; i++) {
          nSubNet[i] = parseInt(strDianFenIP[i]) & parseInt(DianFenMask[i])
        }
        let strSubNet =
          nSubNet[0] + '.' + nSubNet[1] + '.' + nSubNet[2] + '.' + nSubNet[3]
        this.items.push({ titel: '子网:', message: strSubNet })
      }
      //console.log(nSubNet)
      let nWildcardMask = []
      let cbIP = 1
      let nMaxIP = []
      if (isMask) {
        for (let i = 0; i < 4; i++) {
          nWildcardMask[i] = 255 - parseInt(DianFenMask[i])
        }
        //console.log(nWildcardMask)
        for (let i = 0; i < 4; i++) {
          cbIP += 256 ** (3 - i) * nWildcardMask[i]
          // this.items.push({ message: nWildcardMask[i] })
          nMaxIP[i] = nWildcardMask[i] + nSubNet[i]
        }
        this.items.push({ titel: '包含IP数量:', message: cbIP })
        if (isIP) {
          this.items.push({
            titel: '广播地址:',
            message:
            nMaxIP[0] + '.' + nMaxIP[1] + '.' + nMaxIP[2] + '.' + nMaxIP[3]
          })
        }
        this.items.push({
          titel: '子网掩码:',
          message:
            DianFenMask[0] +
            '.' +
            DianFenMask[1] +
            '.' +
            DianFenMask[2] +
            '.' +
            DianFenMask[3]
        })
        this.items.push({
          titel: 'MASK求逆:',
          message:
            nWildcardMask[0] +
            '.' +
            nWildcardMask[1] +
            '.' +
            nWildcardMask[2] +
            '.' +
            nWildcardMask[3]
        })
        let maskCount = 0
        let a255 = new Array(0,128,192,224,240,248,252,254,255)
        //let a255 = new Array(255,254,252,248,240,224,192,128,0)
        for (let x in DianFenMask){
          for (let y in a255){
            if (DianFenMask[x]==a255[y]){
              maskCount += parseInt(y)
            }
          }
          //strB += parseInt(DianFenMask[x]).toString(2)
        }
        //this.dialogMsg+='子网掩码(二进制位):'+maskCount+'\n'
        this.items.push({
          titel: '子网掩码(位):',
          message: maskCount
        })
      }else{
        this.dialogMsg+='子网掩码不合法:'+strMask+'\n'
      }
      // `this` 在方法里指向当前 Vue 实例
      // alert('Hello ' + this.name + '!')
      // `event` 是原生 DOM 事件
      if (event) {
        // alert(event.target.tagName)
        //console.log(event.target.tagName)
      }
      //this.items.push({ titel: '', message: '' })
      this.dialogVisible = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}
body{
  background-color: #2d3a4b;
}
/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .IPtable {
  margin-inline-start: auto;
  margin-inline-end: auto;
}
  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>