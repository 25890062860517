<template>
  <div id="app">
    <router-view/>
    <footer class="footer navbar-fixed-bottom foot-wrap" id="footer1">
      <div class="container">
        <div class="w-1000 copyright">
          Copyright © 2015-2021    <strong><a href="//www.mmemm.com/" target="_blank" style="color:#2c3e50">没墨网</a></strong>&nbsp;
          <strong><a href="//www.mmemm.com/" target="_blank" style="color:#2c3e50">mmemm.com</a></strong> All Rights Reserved. 备案号：<a target="_blank" rel="nofollow" href="http://www.beian.miit.gov.cn" style="color:#2c3e50">陕ICP备15003808号</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
#footer1{
  position: absolute;
  bottom: 0;
  text-align: center;
  background-color: teal;
  width: 100%;
  margin:0 -8px;
}
a{
  color: #2c3e50;
}
</style>
