<template>
  <div class="IPtools">
      <IP msg="Welcome to Your IP Calculator"/> 
  </div>
</template>

<script>
// @ is an alias to /src
import IP from '@/components/IPCalculator.vue'

export default {
  name: 'IPtools',
  components: {
    IP
  }
}
</script>
